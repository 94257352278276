import {
  ArrowLeftOutlined,
  CloseOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Image,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Space,
  Switch,
  Upload,
} from "antd";
import { useEffect, useState } from "react";
import ShowLayeredImages from "./showLayersImages";
import NotificationService from "../../Services/notification";
import MessageNotification from "../Common/messageNotification";
import { isJson } from "../../Util/commonUtility";

const AddTemplate = (props) => {
  const { onFinish, onFinishFailed, backToListPage, editTemplateData } = props;
  const [addTemplateForm] = Form.useForm();
  const [showLoading, setShowLoading] = useState(true);
  const [defaultData, SetDefaultData] = useState(editTemplateData);
  const [json, setJson] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [fields, setFields] = useState([]);

  const [defaultImagesData, setDefaultImagesData] = useState({
    thumbnailImage: "",
    layers: [],
  });

  const ImagePreview = ({ imageUrl }) => {
    return imageUrl ? (
      <Image className="img_border" width={140} height={120} src={imageUrl} />
    ) : null;
  };
  const ImagePreviewLayer = ({ imageUrl }) => {
    return imageUrl ? (
      <Image className="img_border" width={60} height={60} src={imageUrl} />
    ) : null;
  };

  // let layersData = [];

  const onImageUrlChange = (e, action = "layer", index = 0) => {
    setShowLoading(true);
    if (action === "thumbnailImage") {
      setDefaultImagesData({
        ...defaultImagesData,
        thumbnailImage: e.target.value,
      });
      setShowLoading(false);
    } else {
      setDefaultImagesData({
        ...defaultImagesData,
        layers: addTemplateForm.getFieldValue("layers"),
      });
      setShowLoading(false);
    }
  };
  useEffect(() => {
    if (addTemplateForm.getFieldsValue()) {
      setShowLoading(false);
    }
  }, [addTemplateForm]);

  useEffect(() => {
    addTemplateForm.setFieldsValue({
      ...editTemplateData,
      valuePropName: editTemplateData
        ? editTemplateData.isPremium
          ? true
          : false
        : false,
    });
    if (editTemplateData?.layers) {
      setDefaultImagesData({
        ...defaultImagesData,
        thumbnailImage: editTemplateData.thumbnailImage,
        layers: editTemplateData.layers,
      });
    }
    addTemplateForm.setFieldsValue({
      ...editTemplateData,
      valuePropActive: editTemplateData
        ? editTemplateData.activeStatus === true
          ? true
          : false
        : false,
    });
  }, [editTemplateData]);

  const handleFileChange = (event) => {
    const file = event?.fileList[0];
    if (!file) return;

    if (file.type !== "application/json") {
      return;
    }

    const confirmChange = addTemplateForm.getFieldsValue();

    if (confirmChange?.layers?.length > 0) {
      const confirmIt = window.confirm(
        "Importing this json will remove all the current data."
      );
      if (confirmIt) {
        addTemplateForm.resetFields();
      } else {
        return;
      }
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const parsedJson = JSON.parse(e.target.result);

        // Validation for object name and thumbnail URL
        if (!parsedJson.name || !parsedJson.thumbnailImage) {
          MessageNotification(
            "error",
            "Object name or thumbnail URL is missing."
          );
          return;
        }

        // Validation for layers array
        if (!parsedJson.layers || parsedJson.layers.length === 0) {
          MessageNotification("error", "Layers array is empty.");
          return;
        }

        // Validation for each layer object
        for (const layer of parsedJson.layers) {
          if (!layer.imageUrl) {
            MessageNotification(
              "error",
              "Image URL is missing in one or more layers."
            );
            return;
          } else if (!layer.type) {
            MessageNotification(
              "error",
              "Type is missing in one or more layers."
            );
          }
        }
        const mergedData = {
          ...parsedJson,
        };

        mergedData?.layers?.forEach((layer) => {
          layer.data_object = Object.entries(layer.data_object).map(
            ([key, value]) => ({
              key,
              value,
            })
          );
        });
        addTemplateForm.setFieldsValue(mergedData);
        setDefaultImagesData({
          ...defaultImagesData,
          thumbnailImage: addTemplateForm.getFieldValue("thumbnailImage"),
          layers: addTemplateForm.getFieldValue("layers"),
        });

        setJson(mergedData);
        setFileList([]);
      } catch (error) {
        console.error("Invalid JSON file.");
      }
    };
    reader.readAsText(file.originFileObj);
  };
  const handleRemoveConfirm = () => {
    const confirmDelete = window.confirm(
      "Removing this file will reset the fields, are you sure you want to remove the JSON file?"
    );

    if (confirmDelete) {
      setFileList([]);
      addTemplateForm.resetFields();
      setJson(false);
    }
  };
  const changeValueTypeForDataObject = (event, dataObjectkey, layersKey) => {
    const originalDataObject = addTemplateForm.getFieldsValue();

    let currentObject =
      originalDataObject.layers[layersKey].data_object[dataObjectkey];
    const text = event.target.value;

    const isNumeric = /^\d+$/.test(text);

    const newText = isNumeric ? Number(text) : text;

    currentObject.value = newText;
  };
  const layerComponent = () => {
    return (
      <Form.List
        name="layers"
        rules={[
          {
            validator: async (_, layers) => {
              if (!layers || layers.length < 1) {
                return Promise.reject(new Error("At least 1 layers needed"));
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }) => {
          return (
            <>
              {fields?.map(({ key: layersKey, name, ...restField }) => (
                <Card
                  title={`Layer ${name + 1}`}
                  className=" layer_align "
                  key={layersKey}
                >
                  <Row>
                    <Col span={6}>
                      <Form.Item
                        className="add_temp_layer_align"
                        name={[name, "friendlyName"]}
                        rules={[
                          {
                            required: true,
                            message: "Please enter layer name!",
                          },
                        ]}
                      >
                        <Input
                          style={{ marginBottom: "15px" }}
                          placeholder="Please enter layer name"
                          defaultValue={
                            editTemplateData
                              ? editTemplateData.layers.length > name
                                ? editTemplateData.layers[name].friendlyName
                                : ""
                              : ""
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label="Layers Sequence"
                        name={[name, "sequence"]}
                        rules={[
                          {
                            required: false,
                            message: "Please input your layer sequence!",
                          },
                        ]}
                        initialValue={name}
                      >
                        <InputNumber
                          min={0}
                          initialValue={name}
                          value={name}
                          readOnly
                        />
                      </Form.Item>
                      <Form.Item>
                        <Form.List name={[name, "data_object"]}>
                          {(fields, { add, remove }) => (
                            <div
                              className="sub-list"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                rowGap: 16,
                              }}
                            >
                              <h4>Data Object</h4>
                              {fields.map(({ key, name, ...restField }) => (
                                <Space
                                  key={key}
                                  style={{ width: "100%", marginBottom: "8px" }}
                                  align="baseline"
                                  id=""
                                >
                                  <Form.Item
                                    {...restField}
                                    name={[name, "key"]} // Update the name to include subIndex
                                    style={{
                                      marginRight: "8px",
                                      marginBottom: 0,
                                    }}
                                    // Set initial value as key
                                  >
                                    <Input placeholder="Key" />
                                  </Form.Item>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "value"]}
                                    style={{
                                      marginRight: "8px",
                                      marginBottom: 0,
                                    }}
                                  >
                                    <Input
                                      placeholder="Value"
                                      onChange={(event) =>
                                        changeValueTypeForDataObject(
                                          event,
                                          key,
                                          layersKey
                                        )
                                      }
                                    />
                                  </Form.Item>
                                  <CloseOutlined onClick={() => remove(name)} />
                                </Space>
                              ))}

                              <Button
                                style={{ width: "30%" }}
                                type="dashed"
                                onClick={() => add()}
                                block
                                icon={<PlusOutlined />}
                              />
                            </div>
                          )}
                        </Form.List>
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item
                        className="add_temp_layer_align"
                        name={[name, "imageUrl"]}
                        rules={[
                          {
                            required: true,
                            message: "Input layer image URL!",
                          },
                          {
                            type: "url",
                            warningOnly: true,
                            message: "Please enter valid url",
                          },
                        ]}
                        initialValue={
                          editTemplateData
                            ? editTemplateData.layers.length > name
                              ? editTemplateData.layers[name].imageUrl
                              : ""
                            : ""
                        }
                      >
                        <Input
                          style={{ marginBottom: "15px" }}
                          onChange={(e) => {
                            onImageUrlChange(e);
                          }}
                          placeholder="Please enter layer image URL!"
                          defaultValue={
                            editTemplateData &&
                            editTemplateData.layers.length > name
                              ? editTemplateData.layers[name].imageUrl
                              : defaultData?.thumbnailImage
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="">
                        <ImagePreviewLayer
                          imageUrl={addTemplateForm.getFieldValue([
                            "layers",
                            name,
                            "imageUrl",
                          ])}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      {fields.length > 0 ? (
                        <Button
                          type="primary"
                          danger
                          className="dynamic-delete-button"
                          onClick={() => {
                            remove(name);
                            let data = defaultImagesData.layers;
                            if (name >= 0 && name < data.length) {
                              defaultImagesData.layers.splice(name, 1); // Remove 1 item at the specified index
                              setDefaultImagesData({
                                ...defaultImagesData,
                                layers: defaultImagesData.layers,
                              });
                            } else {
                            }
                          }}
                          icon={<CloseOutlined />}
                        ></Button>
                      ) : null}
                    </Col>
                  </Row>
                </Card>
              ))}
              <Divider className="divider_temp" />
              <Form.Item className="add_temp_layer_btn">
                <Button type="dashed" onClick={() => add(fields.name)}>
                  <PlusOutlined /> Add Layer
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
    );
  };
  return (
    <>
      <div className="temp_container">
        <p className="add_temp_btn">
          {editTemplateData ? "Edit" : "Add"} template
        </p>
      </div>

      <div className="json-container">
        <Upload
          type="file"
          accept=".json"
          onChange={handleFileChange}
          onRemove={(file) => {
            handleRemoveConfirm(file);
            return false;
          }}
          fileList={fileList}
          customRequest={true}
        >
          <Button className="import_json_btn" icon={<UploadOutlined />}>
            Import JSON
          </Button>
        </Upload>
      </div>

      <Divider className="divider2"></Divider>
      <div className="add_temp_flex">
        <div className="add_temp_width">
          <Form
            form={addTemplateForm}
            className="common-form"
            name="add-product"
            // labelCol={{
            //   span: 8,
            // }}
            // wrapperCol={{
            //   span: 16,
            // }}
            initialValues={editTemplateData}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item hidden={true} name="_id">
              <Input />
            </Form.Item>
            <div className="layer_direction">
              <div className="add_temp_form">
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "50%",
                  }}
                >
                  <Form.Item
                    label="Premium"
                    name="isPremium"
                    valuePropName="checked"
                    rules={[
                      {
                        required: false,
                        message: "Please select template sub type!",
                      },
                    ]}
                  >
                    <Switch checkedChildren="Yes" unCheckedChildren="No" />
                  </Form.Item>

                  <Form.Item
                    label="Active Status"
                    name="activeStatus"
                    valuePropActive="checkedActive"
                    rules={[
                      {
                        required: false,
                        message: "Please select Active Status",
                      },
                    ]}
                  >
                    {editTemplateData && (
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        defaultChecked={editTemplateData.activeStatus}
                      />
                    )}
                    {!editTemplateData && (
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        defaultChecked="Yes"
                      />
                    )}
                  </Form.Item>
                </Row>

                <Form.Item
                  label="Sequence"
                  name="sequence"
                  rules={[
                    {
                      required: false,
                      message: "Please enter template sequence!",
                    },
                  ]}
                >
                  {editTemplateData && (
                    <InputNumber
                      placeholder="Please enter template sequence"
                      min={editTemplateData.sequence === null ? null : 1}
                      defaultValue={
                        editTemplateData.sequence === null ? null : 1
                      }
                      initialValue={
                        editTemplateData.sequence === null ? null : 1
                      }
                    />
                  )}

                  {!editTemplateData && (
                    <InputNumber
                      placeholder="Please enter template sequence"
                      min={1}
                      defaultValue={1}
                      initialValue={1}
                    />
                  )}
                </Form.Item>

                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter template name!",
                    },
                  ]}
                >
                  <Input
                    className="add_temp_input"
                    placeholder="Please enter template name"
                  />
                </Form.Item>
                <div className="temp_img">
                  <Form.Item
                    label="Thumbnail Image"
                    name="thumbnailImage"
                    rules={[
                      {
                        required: true,
                        message: "Please enter template thumbnailImage!",
                      },
                      {
                        type: "url",
                        warningOnly: true,
                        message: "Please enter valid url",
                      },
                    ]}
                  >
                    <Input
                      className="add_temp_input_for_img"
                      placeholder="Please enter template name"
                      onChange={(e) => {
                        onImageUrlChange(e, "thumbnailImage");
                      }}
                      defaultValue={
                        editTemplateData ? editTemplateData?.thumbnailImage : ""
                      }
                    />
                  </Form.Item>

                  <Form.Item label="">
                    <ImagePreview
                      imageUrl={
                        defaultImagesData.thumbnailImage ??
                        defaultImagesData.thumbnailImage
                      }
                    />
                  </Form.Item>
                </div>
                <Form.Item label="Width" name="width">
                  <Input
                    className="add_temp_input"
                    placeholder="Please enter template width"
                  />
                </Form.Item>
                <Form.Item label="Height" name="height">
                  <Input
                    className="add_temp_input"
                    placeholder="Please enter template height"
                  />
                </Form.Item>
              </div>

              <div className="add_temp_img_box">
                {showLoading ? (
                  "Loading"
                ) : (
                  <ShowLayeredImages images={defaultImagesData.layers} />
                )}
              </div>
            </div>

            <div>{layerComponent()}</div>

            <Form.Item
              className="temp_btns"
              // wrapperCol={{
              //   offset: 8,
              //   span: 16,
              // }}
            >
              <Space>
                <Button
                  type="default"
                  icon={<ArrowLeftOutlined />}
                  onClick={() => {
                    backToListPage();
                  }}
                >
                  Back
                </Button>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};
export default AddTemplate;
